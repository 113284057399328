<template>
	<el-config-provider :locale="language === 'en' ? en : zhCn">
		<router-view />
	</el-config-provider>
</template>

<script setup>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import en from 'element-plus/dist/locale/en.mjs'
import { useStore } from 'vuex'
import { computed } from 'vue'
const store = useStore()
const language = computed(() => store.getters['common/language'])
</script>
