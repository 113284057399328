const links = {
	// 谷歌下载
	google_play_url: 'https://itunes.apple.com/app/id6504796142',
	// 苹果下载
	app_store_url: 'https://itunes.apple.com/app/id6504796142',
	// 安卓下载
	android_store_url: 'http://www.cntravelapp.com/ChinaTravel.apk',
	// 安卓下载
	android_store_en_url: 'http://www.cntravelapp.com/ChinaTravel.apk',
	// 三星下载
	galaxy_store_cn_url: 'https://galaxy.store/ms100',
	// 三星下载
	galaxy_store_en_url: 'https://galaxy.store/ms100',
	// 油管视频
	youtube_light_url: 'https://www.youtube.com/watch?v=asYq96KPTsw',
	// 哔哩哔哩视频
	bilibili_light_url: 'https://www.bilibili.com/video/BV1wmHjeaExZ/?share_source=copy_web&vd_source=e198e8c47f97cf13e680e1b6c81ac404',
	// 脸书主页
	facebook_url: 'https://www.facebook.com/profile.php?id=61564313819180&is_tour_completed',
	// ins主页
	ins_url: 'https://www.instagram.com/chinatravelapp/',
	// twitter主页
	tiwwer_url: 'https://x.com/ChinaTravelApp',
	// youtube主页
	youtube_url: 'https://www.youtube.com/channel/UC-JXfpuqTcz4UtH2ueVtQ9A',
	// bilibili主页
	bilibili_url: 'https://space.bilibili.com/3537106076961167',
	// 抖音主页
	douyin_url: 'https://v.douyin.com/iry4LS9L/ 0@8.com',
}

export default links
