import JSEncrypt from 'jsencrypt'

const encryptor = new JSEncrypt()

const publicKey = `MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvLVoaJH/bDC9Ccl/nXAdUtbit64cSvjD4WOlIGMs8CJXlwGB35vkFVhXQu3+YM0MJ+pDuKYXkc/bq/lLpZCSu8KqafFZUKRuabl7v/ZQHCcBQ8Nomz2OX4ZlLFZlia6ZItwqXUWXH7qO4Pog9Wf2P4aOEamG5juGDPEItwsNSH9BbXSw7ks0vMeMi2YKIaEr4lsZIYcEnND43ml9hNWBPvc3Ea2OeOQP//v4gMioXpJDfY1/FmY42Y3MkFE8eMslTt/tP2TOq2KzhxhB/VLlPY75OoR3BoqO2S78gGoiytoNB4ARUFTJtBVdRO6WUwbTtyAoM/eu2XgCPkPbw0E20QIDAQAB`

encryptor.setPublicKey(publicKey)

/**
 * 加密数据
 * @param data 需要加密的字段
 * @returns {string | false} 加密后的数据
 */
function encryptData(data) {
	return encryptor.encrypt(data)
}

export { encryptData }
