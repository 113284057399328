import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import userRouter from './modules/center'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: 'Home'
    }
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      title: 'Privacy'
    }
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      title: 'Terms'
    }
  },
  {
    path: '/QRcode',
    name: 'QRcode',
    component: () => import(/* webpackChunkName: "QRcode" */ '../views/QRcode.vue'),
    meta: {
      title: 'QRcode'
    }
  },
  userRouter
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 }
  }
})

export default router
