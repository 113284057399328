export default {
    home: {
        '首页': 'Front page',
        '信息': 'Information',
        '世界和平，享受旅游': 'World Peace，Enjoying Travel',
        '登录': 'Login',
        '注册': 'Register',
        '账号': 'account',
        '密码': 'password',
        '确认密码': 'Confirm Password',
        '邮箱': 'Email',
        '验证码': 'Verification Code',
        '发送验证码': 'Send code',
        '发送成功': 'Send successfully',
        '发送失败': 'Send failed',
        '注册成功': 'Successful registration',
        '注册失败': 'Registration failed',
        '两次密码不一致': 'The two passwords do not match',
        '邮箱不能为空': 'Email address cannot be empty',
        '邮箱格式不正确': 'The email format is incorrect',
        '请先登录': 'Please log in first',
        '您已拥有该徽章': 'You already have this badge',
        '是否购买此徽章': 'Do you want to spend ￥5.88 to buy this badge?',
        '购买': 'Buy',
    },
    copyright: {
        '联系我们': 'Contact us',
        '邮箱': 'Email',
        '备案编号': 'Registration Number',
        '隐私政策': 'Privacy Policy',
        '使用条款': 'Terms of use',
    },
    center: {
        '名称': 'Username',
        '账号': 'Account',
        '钱包': 'Wallet',
        '余额': 'Balance',
        '充值': 'Recharge',
        '交易记录': 'Transaction history',
        '个人中心': 'Personal Center',
        '退出登录': 'Sign out',
        'C币账户': 'C-Coin account',
        '常见问题': 'Common problem',
        '账户余额': 'Account balance',
        'C币': 'C Coin',
        '个人信息': 'Personal information',
        '邮箱': 'Email',
        '国籍': 'Nationality',
        '无': 'No information',
        '消费记录': 'Consumption records',
        '购买记录': 'Purchase history',
        '消费时间': 'Consumption time',
        '订单号': 'Order Number',
        '商品名称': 'Product Name',
        '支付金额': 'Payment amount',
        '原价': 'Original Price',
        '消费C币': 'Consume C-coin',
        '消费渠道': 'Consumption channels',
        '订单状态': 'Order Status',
        '购买C币': 'Purchase C-coin',
        '购买数量': 'Purchase quantity',
        '请先阅读并同意支付相关协议': 'Please read and agree to the payment agreement first',
        '更多支付方式': 'More payment methods',
        '前往收银台': 'Go to the checkout counter',
        '我已阅读并同意': 'I have read and agree',
        '《C币用户协议》': 'C Coin User Agreement',
        '请勾选同意协议': 'Please tick the box to agree to the agreement',
        '支付成功': 'Successful',
        '支付失败': 'Failed',
    },
    common: {
        '取消': 'Cancel',
        '确定': 'Confirm',
        '提示': 'Tips',
        '警告': 'Warning',
        '登录成功': 'Login successful',
        '登录失败，请检查账号密码': 'Login failed, please check your account and password',
        '确定退出登录吗?': 'Are you sure to log out?',
        '退出成功': 'Exit successful',
        '取消操作': 'Cancel operation',
        '请求错误': 'Request error',
        '起始时间': 'Start date',
        '结束时间': 'End date',
        '请选择': 'Please select',
        '搜索': 'Search',
        '你不属于这个页面': 'You do not belong to this page',
    },
    country: {
        "中国": "China",
        "丹麦": "Denmark",
        "爱沙尼亚": "Estonia",
        "芬兰": "Finland",
        "希腊": "Greece",
        "冰岛": "Iceland",
        "拉脱维亚": "Latvia",
        "立陶宛": "Lithuania",
        "马耳他": "Malta",
        "波兰": "Poland",
        "葡萄牙": "Portugal",
        "斯洛伐克": "Slovakia",
        "斯洛文尼亚": "Slovenia",
        "瑞典": "Sweden",
        "摩纳哥": "Monaco",
        "俄罗斯": "Russia",
        "英国": "United Kingdom",
        "塞浦路斯": "Cyprus",
        "保加利亚": "Bulgaria",
        "罗马尼亚": "Romania",
        "乌克兰": "Ukraine",
        "塞尔维亚": "Serbia",
        "克罗地亚": "Croatia",
        "波黑": "Bosnia and Herzegovina",
        "黑山": "Montenegro",
        "北马其顿": "North Macedonia",
        "阿尔巴尼亚": "Albania",
        "白俄罗斯": "Belarus",
        "挪威": "Norway",
        "美国": "United States",
        "加拿大": "Canada",
        "巴西": "Brazil",
        "墨西哥": "Mexico",
        "阿根廷": "Argentina",
        "智利": "Chile",
        "新西兰": "New Zealand",
        "韩国": "South Korea",
        "日本": "Japan",
        "文莱": "Brunei",
        "阿联酋": "United Arab Emirates",
        "捷克": "Czech Republic",
        "法国": "France",
        "德国": "Germany",
        "意大利": "Italy",
        "荷兰": "Netherlands",
        "西班牙": "Spain",
        "瑞士": "Swiss Confederation",
        "爱尔兰": "Ireland",
        "匈牙利": "Hungary",
        "奥地利": "Austria",
        "比利时": "Belgium",
        "卢森堡": "Luxembourg",
        "澳大利亚": "Australia",
        "卡塔尔": "Qatar",
        "新加坡": "Singapore",
        "马来西亚": "Malaysia",
        "泰国": "The Kingdom of Thailand",
        "格鲁吉亚": "Georgia",
    }
}