<template>
	<!-- 尾部 -->
	<div id="footer" class="footer">
		<img alt="" src="@/assets/image/logo.png" />
		<div class="footer-copyright">
			<div class="email">
				<span class="footer-text">{{ t('copyright.联系我们') }}</span>
				<span> {{ t('copyright.邮箱') }}：moshounet@outlook.com</span>
			</div>
			<div class="filing">
				<span class="footer-text">{{ t('copyright.备案编号') }}</span>
				<a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2024111751号-1</a>
				<a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2024111751号-2A</a>
			</div>
			<div class="info">
				<span class="privacy" @click="router.push('privacy')">{{ t('copyright.隐私政策') }}</span>
				<span class="privacy" @click="router.push('terms')">{{ t('copyright.使用条款') }}</span>
			</div>
		</div>
		<div class="footer-web">
			<span class="footer-web-title">FOLLOWS US</span>
			<div class="web-main">
				<a :href="Links.facebook_url" target="_blank"> <img alt="" src="@/assets/image/facebook.png" /></a>
				<a :href="Links.ins_url" target="_blank"> <img alt="" src="@/assets/image/ins.png" /></a>
				<a :href="Links.tiwwer_url" target="_blank"> <img alt="" src="@/assets/image/tiwwer.png" /></a>
				<a :href="Links.youtube_url" target="_blank"> <img alt="" src="@/assets/image/youtube.png" /></a>
				<a :href="Links.bilibili_url" target="_blank"> <img alt="" src="@/assets/image/bilibili.png" /></a>
				<a :href="Links.douyin_url" target="_blank"> <img alt="" src="@/assets/image/douyin.png" /></a>
			</div>
		</div>
	</div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Links from '@/config/Links'

const { t } = useI18n()
const router = useRouter()
</script>

<style lang="less" scoped>
.footer {
	width: 80%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0 auto;
	padding: 10px;
	height: 150px;

	img {
		width: 20%;
		padding: 20px;
	}

	.footer-copyright {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		height: 100%;
		font-weight: bold;

		.filing,
		.email {
			white-space: nowrap;

			.footer-text,
			a {
				margin-right: 30px;
			}
		}

		.privacy {
			cursor: pointer;
			transition: all 0.5s;

			&:hover {
				color: rgb(93, 168, 255);
			}
		}

		.info {
			display: flex;
			width: 100%;
			align-items: center;

			span {
				display: block;
				margin-right: 20px;
			}
		}
	}

	.footer-web {
		width: 20%;

		span {
			font-weight: bold;
			display: block;
			width: 100%;
			text-align: center;
		}

		.web-main {
			margin-top: 20px;
			display: flex;
			align-items: center;
			justify-content: space-around;
			width: 100%;

			a {
				width: 12%;
				padding: 0;

				img {
					width: 100%;
					padding: 0;
				}
			}
		}
	}
}
</style>
