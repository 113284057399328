import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locale'
import ElementPlus from 'element-plus'
import Lazyload from 'vue3-lazyload'

// 引入全局样式
import './assets/css/common.css'
import '@/assets/css/variable.less'
import 'element-plus/theme-chalk/index.css'
import './router/permission'
import 'aos/dist/aos.css'

createApp(App).use(store).use(router).use(ElementPlus).use(i18n).use(Lazyload).mount('#app')
