<template>
	<div id="parallax-container" class="parallax-container">
		<ul id="scene" class="scene unselectable" data-friction-x="0.1" data-friction-y="0.1" data-scalar-x="25" data-scalar-y="15">
			<li class="layer" data-depth="0.10">
				<div class="background"></div>
			</li>
			<li class="layer" data-depth="0.15">
				<ul class="rope rope-top-1">
					<li class="hanger position-2">
						<div class="board cloud-2 swing-1"></div>
					</li>
					<li class="hanger position-4">
						<div class="board cloud-1 swing-3"></div>
					</li>
				</ul>
			</li>
			<li class="layer" data-depth="0.30">
				<ul class="rope rope-top-2">
					<li class="hanger position-1">
						<div class="board cloud-1 swing-3"></div>
					</li>
					<li class="hanger position-5">
						<div class="board cloud-2 swing-1"></div>
					</li>
				</ul>
			</li>
			<li class="layer" data-depth="0.60">
				<ul class="rope rope-top-3">
					<li class="hanger position-6">
						<div class="board cloud-2 swing-2"></div>
					</li>
					<li class="hanger position-8">
						<div class="board cloud-1 swing-4"></div>
					</li>
				</ul>
			</li>
		</ul>
	</div>
</template>

<script setup>
import Parallax from 'parallax-js'
import { onMounted } from 'vue'

onMounted(() => {
	new Parallax(document.getElementById('scene'))
})
</script>

<style lang="less" scoped>
.parallax-container {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;

	.scene {
		display: block;
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
		position: relative;
		overflow: hidden;

		.layer {
			display: block;
			height: 100%;
			width: 100%;
			padding: 0;
			margin: 0;

			.background {
				background: url(@/assets/image/parallax-bg.png) no-repeat 50% 100%;
				bottom: 96px;
				background-size: cover;
				position: absolute;
				width: 110%;
				left: -5%;
				top: -5%;
			}

			.rope {
				width: 100%;
				position: relative;

				.hanger {
					transform-origin: 0 0;
					position: absolute;

					&::marker {
						display: none;
						content: '';
					}

					.board {
						transform-origin: 50% 0;
						margin: 0 -140px;
						background-size: cover;
						background-repeat: no-repeat;
						position: absolute;
						top: -4px;
						left: 0;
					}

					.cloud-1 {
						background-image: url(@/assets/image/board-cloud-1.png);
						height: 121px;
						width: 305px;
					}

					.cloud-2 {
						background-image: url(@/assets/image/board-cloud-2.png);
						height: 150px;
						width: 588px;
					}
				}

				.position-1 {
					top: 28%;
				}

				.position-2 {
					top: 46%;
				}

				.position-4 {
					top: 66.5%;
				}

				.position-5 {
					top: 69.5%;
				}

				.position-6 {
					top: 66.5%;
				}

				.position-8 {
					top: 46%;
				}

				.position-1 {
					left: 10%;
				}

				.position-2 {
					left: 20%;
				}

				.position-4 {
					left: 50%;
				}

				.position-5 {
					left: 50%;
				}

				.position-6 {
					left: 20%;
				}

				.position-8 {
					left: 90%;
				}
			}

			.rope-top-1 {
				top: 8%;
			}

			.rope-top-2 {
				top: 16%;
			}

			.rope-top-3 {
				top: 24%;
			}
		}
	}
}
</style>
