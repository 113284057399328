import { reqloginUserFin } from '@/api/user/index'
import { ElNotification } from 'element-plus'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { h } from 'vue'
import i18n from '@/locale/index'

const t = i18n.global.t

const state = {
	nameNo: getToken('nameNo'),
	userInfo: getToken('userInfo'),
}

const mutations = {
	SET_NAMENO: (state, nameNo) => {
		state.nameNo = nameNo
	},
	SET_USERINFO: (state, userInfo) => {
		state.userInfo = userInfo
	},
}

const actions = {
	login({ commit }, userInfo) {
		return new Promise((resolve, reject) => {
			reqloginUserFin(userInfo)
				.then((response) => {
					if (response.code === 200) {
						ElNotification({
							title: t('common.提示'),
							message: h('i', { style: 'color: teal' }, t('common.登录成功')),
						})
						commit('SET_NAMENO', response.userInfo.nameNo)
						commit('SET_USERINFO', response.userInfo)
						setToken('nameNo', response.userInfo.nameNo)
						setToken('userInfo', JSON.stringify(response.userInfo))
						resolve()
					}
					if (response.code === 501) {
						ElNotification({
							title: t('common.提示'),
							message: t('common.登录失败，请检查账号密码'),
						})
					}
				})
				.catch((error) => {
					reject(error)
				})
		})
	},

	logout({ commit, state, dispatch }) {
		return new Promise((resolve, reject) => {
			commit('SET_NAMENO', '')
			commit('SET_USERINFO', {})
			removeToken('nameNo')
			removeToken('userInfo')
		})
	},
}

const getters = {
	nameNo: (state) => state.nameNo,
	userInfo: (state) => state.userInfo,
	username: (state) => state.userInfo?.name,
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}
