import { getToken, setToken } from '@/utils/auth'
import i18n from '@/locale/index'

const state = {
    language: getToken('language')
}

const mutations = {
    SET_LANGUAGE: (state, language) => {
        i18n.global.locale.value = language
        state.language = language
    },
}

const actions = {
    setLanguage({ commit }, language) {
        commit('SET_LANGUAGE', language)
        setToken('language', language)
    },
}

const getters = {
    language: (state) => state.language,
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
}
