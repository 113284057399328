import { createStore } from 'vuex'
import user from './modules/user.js'
import common from './modules/common.js';
import persistedState from 'vuex-persistedstate';

export default createStore({
  modules: {
    user,
    common
  },
  plugins: [
    persistedState({
      storage: window.localStorage,
    }),
  ],
})
