import router from './index'
import store from '../store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/get-page-title'

NProgress.configure({ showSpinner: false })

const whiteList = ['/', '/privacy', '/terms', '/QRcode']

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = getPageTitle(to.meta.title)
  const nameNo = store.getters['user/nameNo']
  if (!nameNo) {
    if (whiteList.indexOf(to.path) !== -1) {
      return next()
    } else {
      return next('/')
    }
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})
