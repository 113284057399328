import request from '@/utils/request'

export function reqPriceAll() {
    return request({
        url: '/gold/selectAll ',
        method: 'post'
    })
}

export function reqOrderQrcode(data) {
    return request({
        url: '/alipay/tradeCreate',
        method: 'post',
        data
    })
}

export function reqAddUserGoldFin(data) {
    return request({
        url: '/userGold/addUserGoldFin',
        method: 'post',
        data
    })
}

export function reqUpdateUserBuyRecordByName(data) {
    return request({
        url: '/userBuyRecordInfo/updateUserBuyRecordByName',
        method: 'post',
        data
    })
}

export function reqUserGold(data) {
    return request({
        url: '/userGold/selectAllByName',
        method: 'post',
        data
    })
}

export function reqAllOrderByName(data) {
    return request({
        url: '/userBuyRecordInfo/selectAllByTimeAndIsPayment',
        method: 'post',
        data
    })
}

export function reqAllCommodityInfo(data) {
    return request({
        url: '/commodityInfo/queryAllByType',
        method: 'post',
        data
    })
}

export function reqUserCommodityInfo(data) {
    return request({
        url: '/userCommodityInfo/queryAllByName',
        method: 'post',
        data
    })
}
