import request from '@/utils/request'

export function reqloginUserFin(data) {
    return request({
        url: '/user/loginUserFin',
        method: 'post',
        data
    })
}

export function registerUser(data) {
    return request({
        url: '/user/addUserByEmail',
        method: 'post',
        data
    })
}