import axios from 'axios'
import { ElMessage } from 'element-plus'
import i18n from '@/locale/index'

const global = i18n.global

const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	timeout: 5000,
	withCredentials: false, // 表示跨域请求时是否需要使用凭证
})

// 请求拦截器
service.interceptors.request.use(
	(config) => {
		config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
		config.headers['Access-Control-Allow-Origin'] = '*'
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

// 响应拦截器
service.interceptors.response.use(
	(response) => {
		const res = response.data
		if (res.code === 200) {
			return res
		}
		if (res.code === 501 || res.code === 502) {
			let message = global.locale.value === 'zh-Hans' ? res.msg : res.msgEn
			ElMessage({
				message,
				type: 'error',
				duration: 5 * 1000,
			})
			return Promise.reject(new Error(message || 'Error'))
		}
	},
	(error) => {
		console.log('err' + error)
		ElMessage({
			message: error.message,
			type: 'error',
			duration: 5 * 1000,
		})
		return Promise.reject(error)
	}
)

export default service
