const userRouter = {
    path: '/center',
    name: 'center',
    component: () => import('../../views/center/index.vue'),
    meta: {
        title: '个人中心',
    },
    redirect: '/center/userinfo',
    children: [
        {
            path: 'balance',
            name: 'balance',
            component: () => import('../../views/center/balance.vue'),
            meta: {
                title: 'Balance',
            },
        },
        {
            path: 'recharge',
            name: 'recharge',
            component: () => import('../../views/center/recharge.vue'),
            meta: {
                title: 'Recharge',
            },
        },
        {
            path: 'trade',
            name: 'trade',
            component: () => import('../../views/center/trade.vue'),
            meta: {
                title: 'Trade',
            },
        },
        {
            path: 'userinfo',
            name: 'userinfo',
            component: () => import('../../views/center/userinfo.vue'),
            meta: {
                title: 'UserInfo',
            },
        },
        {
            path: 'successfulPayment',
            name: 'successfulPayment',
            component: () => import('../../views/center/successfulPayment.vue'),
            meta: {
                title: 'successfulPayment',
            },
        },
    ]
}

export default userRouter
