import { createI18n } from "vue-i18n";
import elementEnLocale from 'element-plus/es/locale/lang/en.mjs'
import elementZhLocale from 'element-plus/es/locale/lang/zh-cn.mjs'
import zhHans from "./zh-Hans.js"
import en from "./en.js";
import { getToken } from '@/utils/auth'
const messages = {
  en: {
    ...en,
    ...elementEnLocale
  },
  "zh-Hans": {
    ...zhHans,
    ...elementZhLocale
  }
};
const storedLocale = getToken('language') || "en";
const i18nConfig = {
  locale: storedLocale,
  messages,
  legacy: false,
  globalInjection: true
};
const i18n = createI18n(i18nConfig);
export default i18n;
