export default {
    home: {
        '首页': '首页',
        '信息': '信息',
        '世界和平，享受旅游': '世界和平，享受旅游',
        '登录': '登录',
        '注册': '注册',
        '账号': '账号',
        '密码': '密码',
        '确认密码': '确认密码',
        '邮箱': '邮箱',
        '验证码': '验证码',
        '发送验证码': '发送验证码',
        '发送成功': '发送成功',
        '发送失败': '发送失败',
        '注册成功': '注册成功',
        '注册失败': '注册失败',
        '两次密码不一致': '两次密码不一致',
        '邮箱不能为空': '邮箱不能为空',
        '邮箱格式不正确': '邮箱格式不正确',
        '请先登录': '请先登录',
        '您已拥有该徽章': '您已拥有该徽章',
        '是否购买此徽章': '是否花费￥5.88购买此徽章',
        '购买': '购买',
    },
    copyright: {
        '联系我们': '联系我们',
        '邮箱': '邮箱',
        '备案编号': '备案编号',
        '隐私政策': '隐私政策',
        '使用条款': '使用条款',
    },
    center: {
        '名称': '名称',
        '账号': '账号',
        '钱包': '钱包',
        '余额': '余额',
        '充值': '充值',
        '交易记录': '交易记录',
        '个人中心': '个人中心',
        '退出登录': '退出登录',
        'C币账户': 'C币账户',
        '常见问题': '常见问题',
        '账户余额': '账户余额',
        'C币': 'C币',
        '个人信息': '个人信息',
        '邮箱': '邮箱',
        '国籍': '国籍',
        '无': '无',
        '消费记录': '消费记录',
        '购买记录': '购买记录',
        '消费时间': '消费时间',
        '订单号': '订单号',
        '商品名称': '商品名称',
        '支付金额': '支付金额',
        '原价': '原价',
        '消费C币': '消费C币',
        '消费渠道': '消费渠道',
        '订单状态': '订单状态',
        '购买C币': '购买C币',
        '购买数量': '购买数量',
        '请先阅读并同意支付相关协议': '请先阅读并同意支付相关协议',
        '更多支付方式': '更多支付方式',
        '前往收银台': '前往收银台',
        '我已阅读并同意': '我已阅读并同意',
        '《C币用户协议》': '《C币用户协议》',
        '请勾选同意协议': '请勾选同意协议',
        '支付成功': '支付成功',
        '支付失败': '支付失败',
    },
    common: {
        '取消': '取消',
        '确定': '确定',
        '提示': '提示',
        '警告': '警告',
        '登录成功': '登录成功',
        '登录失败，请检查账号密码': '登录失败，请检查账号密码',
        '确定退出登录吗?': '确定退出登录吗?',
        '退出成功': '退出成功',
        '取消操作': '取消操作',
        '请求错误': '请求错误',
        '起始时间': '起始时间',
        '结束时间': '结束时间',
        '请选择': '请选择',
        '搜索': '搜索',
        '你不属于这个页面': '你不属于这个页面',
    },
    country: {
        "中国": "中国",
        "丹麦": "丹麦",
        "爱沙尼亚": "爱沙尼亚",
        "芬兰": "芬兰",
        "希腊": "希腊",
        "冰岛": "冰岛",
        "拉脱维亚": "拉脱维亚",
        "立陶宛": "立陶宛",
        "马耳他": "马耳他",
        "波兰": "波兰",
        "葡萄牙": "葡萄牙",
        "斯洛伐克": "斯洛伐克",
        "斯洛文尼亚": "斯洛文尼亚",
        "瑞典": "瑞典",
        "摩纳哥": "摩纳哥",
        "俄罗斯": "俄罗斯",
        "英国": "英国",
        "塞浦路斯": "塞浦路斯",
        "保加利亚": "保加利亚",
        "罗马尼亚": "罗马尼亚",
        "乌克兰": "乌克兰",
        "塞尔维亚": "塞尔维亚",
        "克罗地亚": "克罗地亚",
        "波黑": "波黑",
        "黑山": "黑山",
        "北马其顿": "北马其顿",
        "阿尔巴尼亚": "阿尔巴尼亚",
        "白俄罗斯": "白俄罗斯",
        "挪威": "挪威",
        "美国": "美国",
        "加拿大": "加拿大",
        "巴西": "巴西",
        "墨西哥": "墨西哥",
        "阿根廷": "阿根廷",
        "智利": "智利",
        "新西兰": "新西兰",
        "韩国": "韩国",
        "日本": "日本",
        "文莱": "文莱",
        "阿联酋": "阿联酋",
        "捷克": "捷克",
        "法国": "法国",
        "德国": "德国",
        "意大利": "意大利",
        "荷兰": "荷兰",
        "西班牙": "西班牙",
        "瑞士": "瑞士",
        "爱尔兰": "爱尔兰",
        "匈牙利": "匈牙利",
        "奥地利": "奥地利",
        "比利时": "比利时",
        "卢森堡": "卢森堡",
        "澳大利亚": "澳大利亚",
        "卡塔尔": "卡塔尔",
        "新加坡": "新加坡",
        "马来西亚": "马来西亚",
        "泰国": "泰国",
        "格鲁吉亚": "格鲁吉亚",
    }
}